import React from 'react';


export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    cName: 'nav-text'
  },
  {
    title: 'About Us',
    path: '/about',
    cName: 'nav-text'
  },
//   {
//     title: 'Services',
//     path: '/services',
//     cName: 'nav-text'
//   },
//   {
//     title: 'Solutions',
//     path: '/solution',
//     cName: 'nav-text'
//   },
//   {
//     title: 'Careers',
//     path: '/careers',
//     cName: 'nav-text'
//   },
  {
    title: 'Contact Us',
    path: '/contact',
    cName: 'nav-text'
  },
  
];