import React from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram, faLinkedinIn} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faPhoneAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"



export default function Footer() {
    return (
        // <div className='footer'>
        //     <h2>@copyright 2022</h2>
        // </div>
        <footer>
            <hr style={{width:"92%", height:"2px", backgroundColor:"#D9D7F1", borderWidth:"0px"}}/>
            <div className='topFooter'>
                <ul >
                    <li><h2>Rival Solutions</h2></li>
                    <li ><a  href='tel:+1 (984)-377-9296' style={{textDecoration:"none"}}><FontAwesomeIcon icon={faPhoneAlt} /> +1 (984)-377-9296</a></li>
                    <li><a href='mailto:admin@rivalsol.com' style={{textDecoration:"none"}}><FontAwesomeIcon icon={faEnvelope}/> admin@rivalsol.com</a></li>
                    <li><FontAwesomeIcon icon={faMapMarkerAlt}/> 1005 Hemby Ridge Ln, Morrisville, NC 27560.</li>
                </ul>
                <ul>
                    <li><h3>Services</h3></li>
                    <li >Service 1</li>
                    <li>Service 2</li>
                    <li>Service 3</li>
                    <li>Service 4</li>

                </ul>
                <ul>
                    <li><h3>Info</h3></li>
                    <li >Info 1</li>
                    <li >Info 2</li>
                    <li >Info 3</li>

                </ul>
                
            </div>
            <hr style={{width:"92%", height:"2px", backgroundColor:"#D9D7F1", borderWidth:"0px"}}/>
            <div className="bottomFooter">
                <p>
                    &copy;{new Date().getFullYear()} Rival Solutions | All rights reserved |    
                    <div className='footerIcons'>
                        <a><FontAwesomeIcon icon={faInstagram}/></a>
                        <a><FontAwesomeIcon icon={faFacebook}/></a>
                        <a><FontAwesomeIcon icon={faTwitter}/></a>
                        <a><FontAwesomeIcon icon={faLinkedinIn}/></a>
                    </div>
                </p>
        </div>
        </footer>
    )
}
