import { Link } from 'gatsby'
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons"

export default function NavigationBar() {
    return (
        
        <nav>
            <h1>Rival Solutions</h1>
            <i>Decision defines destiny</i>
            <div className='links'>
                {/* <BrowserRouter>
                
                    <NavLink exact to='/' activeClassName="active-link">Home</NavLink>
                    <NavLink exact to='/about' activeClassName="active-link" >About Us</NavLink>
                </BrowserRouter> */}

                <div className='mainNav'>
                <Link exact to='/' activeClassName="active-link">Home</Link>
                <Link exact to='/about' activeClassName="active-link" >About Us</Link>
                <Link exact to='/contact' activeClassName="active-link" >Contact Us</Link>
                </div>
            </div>
        </nav>
        
        
    )
}
