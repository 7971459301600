import React from 'react'
import NavigationBar from './NavigationBar'
import "../styles/global.css" 
import Footer from './Footer'
import NavHamBar from './NavHamBar'

export default function Layout({ children }) {
    return (
        <div>
            <NavigationBar /> 
            <NavHamBar />
            <div className='layout'>
                
                {/* All content goes here */}
                <div className='content'>
                    { children }
                </div>

            </div>
            <Footer />
        </div>
    )
}
